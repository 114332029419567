/* Genel Stil */
.products-container {
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  position: relative;
  margin-bottom: 100px;
  min-height: 100vh;
}

.products-title {
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.divider {
  width: auto;
  height: 1px;
  background-color: #000;
  margin: 20px;
  border-radius: 1px;
}

.MuiOutlinedInput-root {
  border-radius: 15px;
}

.MuiTextField-root {
  margin: auto;
}

/* Kategoriler için düzenlemeler */
.product-category-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Kategoriler taşarsa alt sıraya geçer */
  gap: 8px; /* Kategoriler arasında boşluk */
  margin-bottom: 20px;
}

.product-category-chip {
  background-color: #f0f0f0;
  color: #000;
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.product-category-chip.selected {
  background-color: #014DAD;
  color: #ffffff;
}

.product-category-chip:hover {
  background-color: #013f8a;
  color: #ffffff;
}

/* Mobil için kategori dropdown */
@media (max-width: 768px) {
  .product-category-container {
    display: none; /* Mobilde chip'leri gizleyeceğiz */
  }

  .mobile-category-select {
    display: block;
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}
