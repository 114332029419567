.contact-container {
  background: linear-gradient(135deg, #ffffff, #f3f8fe);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  margin-bottom: 50px;
}

.contact-title {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
}

.divider {
  width: 80%;
  height: 2px;
  background-color: #014dad;
  margin: 0 auto 30px;
  border-radius: 1px;
}

.contact-info {
  padding: 20px 0;
  margin-bottom: 15px;
  color: #333;
  font-family: "Roboto", sans-serif;
  text-align: left;
  line-height: 1.6;
}

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.social-icon {
  font-size: 24px;
  color: #014dad;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c13584;
}

.whatsapp {
  color: #25d366;
}

.email {
  color: #014dad;
}

.map iframe {
  padding: 20px 0;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.working-hours {
  margin-top: 40px;
  text-align: center;
}

.working-title {
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.working-schedule {
  margin-top: 20px;
}

.working-info {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 20px 10px;
  }

  .contact-title {
    font-size: 1.8rem;
  }

  .contact-info {
    font-size: 1rem;
    text-align: center;
  }

  .map iframe {
    height: 250px;
  }

  .working-info {
    text-align: center;
  }
}
