.whatsapp-float {
  position: fixed;
  bottom: 20px; /* Ekranın alt kısmına daha yakın */
  right: 20px; /* Sağ tarafa biraz daha yakın */
  z-index: 1000;
  animation: bounce 2s infinite;
  background-color: #25D366;
  border-radius: 50%; /* Yuvarlak ikon */
  padding: 15px; /* Daha büyük padding, daha büyük alan */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Daha belirgin bir gölge */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Tıklanabilir işaret */
}

.whatsapp-icon {
  width: 50px; /* İkonun boyutunu büyütüyoruz */
  height: 50px; /* İkonun boyutunu büyütüyoruz */
  object-fit: contain; /* Görüntüyü düzgün yerleştir */
}

.whatsapp-float:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3); /* Hover durumunda gölgeyi artırıyoruz */
  transform: scale(1.1); /* Hover durumunda biraz büyüme animasyonu */
}

/* Bouncing animasyonu */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Loading ekranı için tam ekran bir container */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  font-family: 'Poppins', sans-serif;
}

/* Logo animasyonu */
.logo-animation h1 {
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  animation: fadeIn 1.5s ease-in-out infinite alternate;
}

.logo-animation h1 span {
  color: #014DAD;
}

/* Fade-in/out animasyonu */
@keyframes fadeIn {
  0% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}
