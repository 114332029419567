.about-container {
  background: linear-gradient(135deg, #ffffff, #f3f8fe);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  margin-bottom: 50px;
  position: relative;
}

.about-title {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #014dad;
  margin: 0 auto 30px;
  border-radius: 1px;
}

.about-text {
  padding: 20px 0;
  font-size: 1.1rem !important;
  line-height: 1.6 !important;
  color: #333 !important;
  text-align: justify !important;
}

.about-image {
  width: 100%;
  height: 350px;
  background: url('../../public/hakkımızda-resim.jpg') no-repeat center center/cover;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icon {
  font-size: 24px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
}

.facebook {
  color: #3b5998 !important;
}

.instagram {
  color: #c13584 !important;
}

.whatsapp {
  color: #25d366 !important;
}

.email {
  color: #014dad !important;
}

@media (max-width: 768px) {
  .about-container {
    padding: 20px 10px;
  }

  .about-title {
    font-size: 1.8rem;
  }

  .about-text {
    font-size: 1rem;
  }

  .about-image {
    height: 250px;
  }
}
