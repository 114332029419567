/* Kart genel stili */
.product-card {
  max-width: 345px;
  height: 450px; /* Sabit yükseklik */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Kart başlığı */
.product-title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin-bottom: 10px;
}

/* Kart eylem butonları */
.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

/* Detaylar butonu */
.details-button {
  color: #004080;
  font-family: 'Poppins', sans-serif !important;
  border: 1px solid #004080;
  transition: background-color 0.3s, color 0.3s;
}

.details-button:hover {
  background-color: #004080;
  color: #fff;
}

/* WhatsApp butonu */
.whatsapp-button {
  color: #25D366 !important;
  transition: transform 0.3s;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

/* Resimlerin düzgün görünmesi */
.product-card img {
  width: 100%;
  height: 250px; /* Sabit resim yüksekliği */
  object-fit: contain; /* Resmin kutuya sığmasını sağlar */
}

/* Kart içerik bölümü */
.MuiCardContent-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
