.category-bar {
  position: static;
  top: 60px;
  z-index: 1000;
  display: flex;
  padding: 10px 20px;
  background-color: #014DAD;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #ddd;
  overflow-x: auto; /* Yatay kaydırma eklendi */
  overflow-y: hidden; /* Dikey taşmaları engelle */
  scrollbar-width: thin; /* İnce bir kaydırma çubuğu için */
}

.category-item {
  display: flex; /* Flex düzeni */
  flex-direction: column; /* Dikey hizalama */
  align-items: center; /* İçeriği yatayda ortala */
  justify-content: space-between; /* İçeriği eşit dağıt */
  text-align: center;
  color: #ffffff;
  font-size: 0.8rem;
  width: 120px; /* Sabit genişlik */
  height: 140px; /* Sabit yükseklik */
  padding: 10px;
  background-color: #015bb5;
  border-radius: 8px;
  text-transform: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-sizing: border-box; /* Padding dahil genişlik/yükseklik */
  overflow: hidden; /* Kutudan taşmayı engelle */
}

.category-item:hover {
  background-color: #013f8a;
  transform: scale(1.05);
}

.category-image {
  width: 70px; /* Görsel boyutu */
  height: 70px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 5px;
  border: 2px solid #ffffff;
}

.category-name {
  font-size: 0.75rem; /* Yazı boyutu */
  color: #ffffff;
  margin-top: 5px;
  text-align: center;
  overflow: hidden; /* Taşmayı gizle */
  white-space: normal; /* Yazıyı satırlara böl */
  word-wrap: break-word; /* Uzun kelimeleri böl */
  display: block;
  line-height: 1.1; /* Satır yüksekliği */
  max-height: 40px; /* En fazla 2 satıra izin ver */
}

.category-item span {
  font-size: calc(10px + 0.2vw); /* Yazıyı dinamik olarak ölçeklendir */
}


/* Duyarlı tasarım için medya sorguları */
@media screen and (max-width: 768px) {
  .category-bar {
    gap: 10px; /* Telefon görünümünde daha az boşluk */
    padding: 10px;
    justify-content: flex-start; /* Kategoriler sola hizalansın */
    overflow-x: auto; /* Yatay kaydırma ekle */
  }

  .category-item {
    width: 100px; /* Daha küçük kutular */
    height: 120px; /* Sabit yükseklik */
    flex-shrink: 0; /* Kutu boyutunu kaydırma çubuğunda küçültme */
  }

  .category-image {
    width: 60px; /* Görsel boyutunu küçült */
    height: 60px;
  }

  .category-name {
    font-size: 0.7rem; /* Yazıyı küçült */
    max-height: 30px; /* Yazının kutu içinde görünmesini sağla */
  }
}
