.home-container {
  padding: 40px 20px;
  position: relative;
  margin-bottom: 100px;
  min-height: 100vh;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 350px;
  background: url('../../public/hero-section.jpg') no-repeat center center/cover;
  border-radius: 20px;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(1, 77, 173, 0.8), rgba(255, 255, 255, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text-box {
  text-align: center;
  color: #ffffff;
  max-width: 600px;
}

.hero-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-buttons {
  display: flex;
  margin-top: 20px;
  gap: 15px;
  justify-content: center;
}

.hero-button {
  text-decoration: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.hero-button.primary {
  background-color: #ffffff;
  color: #014dad;
  border: 2px solid #ffffff;
}

.hero-button.secondary {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.hero-button:hover {
  transform: scale(1.05);
  background-color: #014dad;
  color: #ffffff;
}

.whatsapp-highlight {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.whatsapp-button {
  text-decoration: none;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: bold !important;
  color: white !important;
  background-color: #25D366 !important;
  border-radius: 5px;
  display: flex;
  align-items: center !important;
  transition: background-color 0.3s ease-in-out, transform 0.2s !important;
}

.whatsapp-button:hover {
  background-color: #1EBE57;
  transform: scale(1.1);
}

/* Mobil Görünüm */
@media (max-width: 768px) {
  .hero-section {
    display: none; /* Mobilde hero section tamamen kaybolur */
  }
}

/* Product Section */
.product-section {
  margin-top: 50px;
  background-color: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-title {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.divider {
  width: auto;
  height: 1px;
  background-color: #014dad;
  margin: 20px;
  border-radius: 1px;
}

.see-more-link {
  color: #014dad;
  text-decoration: none;
  font-size: 18px;
  border: 2px solid #014dad;
  padding: 8px 16px;
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s;
}

.see-more-link:hover {
  background-color: #014dad;
  color: #ffffff;
}
